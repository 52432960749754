<template>
    <div class="px-4 pb-5 grid align-items-center" v-if="!is_verify">
        <form class="col-12 md:col-6 xl:col-4 p-fluid pt-2 pb-5" @submit.prevent="doSubmit()">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Source of Fund *
                </label>
                <Dropdown
                    v-model="form.selected_sof"
                    :options="sofs"
                    optionLabel="name"
                    placeholder="Pilih Sumber Dana"
                    :disabled="form.is_submit"
                />
            </div>

            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Username *
                </label>
                <InputText
                    type="text"
                    placeholder="Username | Email"
                    v-model="form.auth.username"
                    :disabled="form.is_submit"
                />
            </div>
            
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Password *
                </label>
                <Password id="password1" v-model="form.auth.password"
                    placeholder="*************"
                    :toggleMask="true"
                    :feedback="false"
                    class="w-full mb-4"
                    inputClass="w-full px-4 roboto-regular border-auth text-black"
                    :disabled="form.is_submit">
                </Password>
            </div>
            <Button type="submit"
                class="btn-primary text-white roboto-semibold font-bold justify-content-center w-auto px-5"
                :disabled="isDisabled || form.is_submit">
                <span v-if="!form.is_submit">Submit</span>
                <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
        </form>
    </div>
    <div class="px-4 grid pb-5" v-else>
        <form class="col-12 md:col-4 p-fluid pt-2 pb-5" @submit.prevent="doVerify()">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    OTP
                </label>
                <InputMask
                    v-model="verify.otp"
                    mask=" 9 9 9 9 9 9"
                    placeholder="e.g. 123456"
                    class="text-center tw-text-xl"
                    style="padding: 1rem"
                    :disabled="verify.is_submit"
                />
            </div>
            <Button label="Cancel" @click="is_verify = false; verify.otp = '';" class="btn-outline-primary mr-3 w-auto" />
            <Button type="submit"
                class="btn-primary text-white roboto-semibold font-bold justify-content-center w-auto px-5"
                :disabled="isDisabled || form.is_submit">
                <span v-if="!form.is_submit">Bind</span>
                <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
        </form>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            is_verify: false,
            sofs: [],
            form: {
                auth: {
                    username: '',
                    password: '',
                },
                selected_sof: '',
                sof_name: '',
                is_submit: false,
            },
            verify: {
                auth: {
                    username: '',
                    password: '',
                    referral: '010',
                },
                otp: '',
                token: '',
                is_submit: false,
            },
        }
    },
    computed: {
        isDisabled() {
            return this.is_verify ? !this.verify.otp  : !this.form.selected_sof || !this.form.auth.username || !this.form.auth.password
        },
    },
    mounted() {
        this.getSoF()
    },
    methods: {
        getSoF() {
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/ref-sof`)
                .then(response => {
                    this.sofs = response.data.data.ref_sofs
                })
        },
        doSubmit() {
            this.form.is_submit = true
            this.form.sof_name = this.form.selected_sof['name']
            this.$http
                .post(`${process.env.VUE_APP_API_URL}/merchant/login`, this.form)
                .then(response => {
                    this.form.is_submit = false
                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }

                    if (!response.data.data.need_confirm) {
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Success!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }
                    
                    this.is_verify = true
                    this.verify.token = response.data.data.token
                }).catch(error => {
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error!', 
                        detail: error.response.data.message, 
                        life: 5000,
                    })
                })
        },
        doVerify() {
            this.verify.is_submit = true
            this.verify.otp = this.verify.otp.replaceAll(/\s/g,'')
            this.verify.auth.username = this.form.auth.username
            this.verify.auth.password = this.form.auth.password
            
            this.$http
                .post(`${process.env.VUE_APP_API_URL}/merchant/login/confirm`, this.verify)
                .then(response => {
                    this.verify.is_submit = false
                    
                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }
                    this.is_verify = false
                    this.verify.otp = ''
                    this.$toast.add({
                        severity:'success', 
                        summary: 'Success!', 
                        detail: response.data.message, 
                        life: 5000,
                    })
                }).catch(error => {
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error!', 
                        detail: error.response.data.message, 
                        life: 5000,
                    })
                })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>